
import Layout from './Layout';


const Home = () => { 
    return (
      <main>
        <Layout/>
      </main>
    )
  }
  
  export default Home;
